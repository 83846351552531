import type { QueryHookOptions, OperationVariables } from '@apollo/client';

import type {
  FindManyCategoryQuery,
  FindManyCategoryQueryVariables,
  SearchCategoriesQuery,
  SearchCategoriesQueryVariables,
  SearchContentsQuery,
  SearchContentsQueryVariables,
  FindRandomJobTitlesQuery,
  FindRandomJobTitlesQueryVariables,
} from '@glass/content/modules/graphql/generated/client';

export const getTaxonomyClientOptions = <
  TData,
  TVariables extends OperationVariables = OperationVariables,
>(
  options: QueryHookOptions<TData, TVariables>,
): QueryHookOptions<TData, TVariables> => options;

export const getFindManyCategoryOptions = getTaxonomyClientOptions<
  FindManyCategoryQuery,
  FindManyCategoryQueryVariables
>;

export const getFindRandomJobTitlesOptions = getTaxonomyClientOptions<
  FindRandomJobTitlesQuery,
  FindRandomJobTitlesQueryVariables
>;

export const getSearchCategoriesOptions = getTaxonomyClientOptions<
  SearchCategoriesQuery,
  SearchCategoriesQueryVariables
>;

export const getSearchContentsOptions = getTaxonomyClientOptions<
  SearchContentsQuery,
  SearchContentsQueryVariables
>;

export default getTaxonomyClientOptions;
