import PropTypes from 'prop-types';
import React from 'react';

import { NONE } from '@glass/common/modules/utils/robots';
import FacebookPixelScriptHead from '@glass/web/components/Facebook/FacebookPixelScriptHead';
import GoogleAnalyticsScriptHead from '@glass/web/components/Google/GoogleAnalyticsScriptHead';
import GoogleTagManagerBodyScript from '@glass/web/components/Google/GoogleTagManagerBodyScript';
import GoogleTagManagerScriptHead from '@glass/web/components/Google/GoogleTagManagerScriptHead';
import { ALL_BREAKPOINTS, DEFAULT_BP } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER } from '@glass/web/modules/theme/constants';

import HrefLangHead from '@glass/shared/components/Head/HrefLangHead';
import ZendeskWidgetScriptHead from '@glass/shared/components/Head/ZendeskWidgetScriptHead';
import Footer from '@glass/shared/components/Layout/Footer/Footer';
import Header from '@glass/shared/components/Layout/Header/Header';
import LayoutPage from '@glass/shared/components/Layout/LayoutPage';
import usePresentation from '@glass/shared/components/hooks/usePresentation';
import useZendesk from '@glass/shared/components/hooks/useZendesk';
import useExtendText from '@glass/shared/modules/content/useExtendText';
import usePageData from '@glass/shared/modules/pageData/usePageData';

function ConsumerLayoutPage({
  children,
  headerProps,
  footerProps,
  robots: robotsProp,
  hideZendesk,
  header: headerProp,
  footer: footerProp,
  pageTitle: pageTitleProp,
  description: descriptionProp,
  className,
  type,
  themeColor,
  canonicalPath: canonicalPathProp,
}) {
  const isPresentation = usePresentation();
  useZendesk({ show: !hideZendesk && !isPresentation });

  const header = headerProp === undefined ? <Header {...headerProps} /> : headerProp;
  const footer = footerProp === undefined ? <Footer {...footerProps} /> : footerProp;

  const pageData = usePageData();
  const { pageTitle, description, canonicalPath, robots } = pageData;
  const extendedPageTitle = useExtendText(pageTitleProp ?? pageTitle);
  const extendedDescription = useExtendText(descriptionProp ?? description);

  return (
    <LayoutPage
      canonicalPath={isPresentation ? null : canonicalPathProp ?? canonicalPath}
      className={className}
      description={extendedDescription}
      footer={!isPresentation ? footer : null}
      header={!isPresentation ? header : null}
      pageTitle={extendedPageTitle}
      robots={isPresentation ? NONE : robotsProp ?? robots}
      themeColor={themeColor}
      type={type}
    >
      <HrefLangHead />
      {isPresentation ? null : <GoogleAnalyticsScriptHead />}
      {isPresentation ? null : <GoogleTagManagerScriptHead />}
      {isPresentation ? null : <FacebookPixelScriptHead />}
      {isPresentation ? null : <ZendeskWidgetScriptHead />}
      {children}
      {isPresentation ? null : <GoogleTagManagerBodyScript />}
    </LayoutPage>
  );
}

ConsumerLayoutPage.propTypes = {
  brand: PropTypes.string,
  canonicalPath: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  footerProps: PropTypes.object,
  header: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  headerProps: PropTypes.object,
  hideZendesk: PropTypes.bool,
  maxWidth: PropTypes.oneOf(ALL_BREAKPOINTS),
  pageTitle: PropTypes.string,
  robots: PropTypes.string,
  themeColor: PropTypes.string,
  type: PropTypes.oneOf([CENTER]),
};

ConsumerLayoutPage.defaultProps = {
  maxWidth: DEFAULT_BP,
};

ConsumerLayoutPage.displayName = 'ConsumerLayoutPage';

export default React.memo(ConsumerLayoutPage);
