import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AutocompleteContentProps,
  AutocompleteContentType,
} from '@glass/web/components/Autocomplete/AutocompleteContent';
import InputField from '@glass/web/components/Autocomplete/InputField';
import { InputProps } from '@glass/web/components/base/Input';
import useIsPriorityLoadedIdle from '@glass/web/modules/loading/useIsPriorityLoadedIdle';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const LazyAutocompleteContent = React.lazy(
  () => import('@glass/web/components/Autocomplete/AutocompleteContent'),
) as AutocompleteContentType;

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  label: {
    display: 'block',
  },
  input: {
    width: 'auto',
    flexGrow: 1,
    '&.Mui-selected': {
      outline: 'none',
    },
  },
  spinnerContainer: {
    position: 'relative',
    marginRight: theme.spacing(1.5),
    marginLeft: 0,
  },
}));

export interface AutocompleteProps<T> {
  value: string;
  suggestions: T[];
  textFieldProps: InputProps;
  getOptionLabel: AutocompleteContentProps<T>['getOptionLabel'];
  filterOptions: AutocompleteContentProps<T>['filterOptions'];
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  textFieldClassName?: string;
  onSelectChange?: AutocompleteContentProps<T>['onSelectChange'];
  autoFocus?: boolean;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
  onDisableSearch?: AutocompleteContentProps<T>['onDisableSearch'];
  isOnChangeExpectingAString?: boolean;
}

export default function Autocomplete<T>({
  disabled,
  autoFocus,
  value,
  suggestions,
  onChange,
  onBlur,
  onFocus,
  onSelectChange,
  getOptionLabel,
  textFieldProps,
  textFieldClassName,
  loading: isLoading,
  inputRef,
  filterOptions,
  onDisableSearch,
  isOnChangeExpectingAString,
}: AutocompleteProps<T>) {
  const [isFocused, setIsFocused] = useState(autoFocus || false);
  const [isReady, setIsReady] = useState(false);

  const isPriorityLoadedIdle = useIsPriorityLoadedIdle();
  const { classes } = useStyles();

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    onBlur?.();
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    onFocus?.();
  }, [onFocus]);

  useEffect(() => {
    // if the user clicks on the input before hydration this is the way to grab if was focused
    if (inputRef?.current === document.activeElement) {
      handleFocus?.();
    }
  }, [handleFocus, inputRef]);

  useEffect(() => {
    if (isFocused || isPriorityLoadedIdle) {
      setIsReady(true);
    }
  }, [isFocused, isPriorityLoadedIdle]);

  const fallbackInput = useMemo(
    () => (
      <InputField
        disabled={disabled}
        className={textFieldClassName}
        isLoading={isLoading}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        autoFocus={isFocused}
        textFieldProps={textFieldProps}
        ref={inputRef}
      />
    ),
    [
      textFieldClassName,
      disabled,
      isLoading,
      onChange,
      handleBlur,
      handleFocus,
      value,
      isFocused,
      textFieldProps,
      inputRef,
    ],
  );

  return (
    <div className={classes.root}>
      {isReady ? (
        <React.Suspense fallback={fallbackInput}>
          <LazyAutocompleteContent<T>
            isOnChangeExpectingAString={isOnChangeExpectingAString}
            autoFocus={isFocused}
            disabled={disabled}
            onSelectChange={onSelectChange}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            textFieldProps={textFieldProps}
            textFieldClassName={textFieldClassName}
            value={value}
            loading={isLoading}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            suggestions={suggestions}
            onDisableSearch={onDisableSearch}
          />
        </React.Suspense>
      ) : (
        fallbackInput
      )}
    </div>
  );
}
