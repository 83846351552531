import PropTypes from 'prop-types';
import React from 'react';

import Container from '@glass/web/components/Layout/Container';
// TODO: Use @glass/shared/components/Button/Button component
import Button from '@glass/web/components/base/Button';
import { CTA_BUTTON_ID } from '@glass/web/modules/content/pages/contentIds';
import { SM } from '@glass/web/modules/theme/breakpointKeys';

import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const SectionButtonCta = React.forwardRef(
  (
    { variant, color, onClick, disabled, component, params, children, href, size, fullWidth },
    ref,
  ) => {
    return (
      <Container isNoXPadding isNoYPadding maxWidth={SM}>
        <Button
          ref={ref}
          color={color}
          component={component}
          disabled={disabled}
          fullWidth={fullWidth}
          href={href}
          params={params}
          size={size}
          variant={variant}
          onClick={onClick}
        >
          {children}
        </Button>
      </Container>
    );
  },
);

SectionButtonCta.defaultProps = {
  size: 'large',
  color: 'primary',
  variant: 'contained',
  fullWidth: true,
};

SectionButtonCta.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default React.memo(withTrackingContext(SectionButtonCta, CTA_BUTTON_ID));
